import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';
import { AUDIT_LOG_FRAGMENT } from '../audit/graphql';

export const TERMINAL_FRAGMENT = gql`
  fragment TerminalFragment on Terminal {
    id
    name
    account {
      id
      name
      balanceInHuf
      balanceInEur
    }
  }
`;

export const CREATE_TERMINAL = gql`
  mutation CreateTerminal($data: CreateTerminalInput!) {
    createTerminal(data: $data) {
      ...TerminalFragment
    }
  }
  ${TERMINAL_FRAGMENT}
`;

export const UPDATE_TERMINAL = gql`
  mutation UpdateTerminal($data: UpdateTerminalInput) {
    updateTerminal(data: $data) {
      ...TerminalFragment
    }
  }
  ${TERMINAL_FRAGMENT}
`;

export const GET_TERMINALS = gql`
  query GetTerminals($options: OptionsInput) {
    terminals(options: $options) @connection(key: "terminals") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...TerminalFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${TERMINAL_FRAGMENT}
`;

export const GET_TERMINALS_OPTIONS = gql`
  query GetTerminalsOptions {
    terminals(options: {}) {
      entries {
        ...TerminalFragment
      }
    }
  }
  ${TERMINAL_FRAGMENT}
`;

export const GET_TERMINAL = gql`
  query GetTerminal($id: String!) {
    terminal(id: $id) {
      ...TerminalFragment
    }

    getAccountAuditLogsByAuditedEntity(userId: $id, options: {}) {
      entries {
        ...AuditLogFragment
      }
    }
  }
  ${TERMINAL_FRAGMENT}
  ${AUDIT_LOG_FRAGMENT}
`;

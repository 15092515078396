import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { GET_AUDIT_LOGS } from '../../graphql';

import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import { Table, CollectionTable } from '../../../common/components';
import AuditLogStatus from './AuditLogStatus';
import AuditEntityType from './AuditEntityType';

const columns = [
  {
    name: 'auditLogSeqId',
    label: 'ID',
    options: {
      renderCell: (value, { id, auditLogSeqId }) => {
        return auditLogSeqId ? routes.auditLog.createLink(id, `AL${auditLogSeqId}`) : null;
      },
      filterType: 'textField',
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'createdBy.fullName',
    label: 'Audited entity',
    options: {
      renderCell: (value, { createdBy, terminalName }) =>
        terminalName
          ? routes.terminal.createLink(terminalName.id, terminalName.name)
          : routes.user.createLink(createdBy, value),
      filterType: 'autocomplete',
    },
  },
  {
    name: 'reportedBy.fullName',
    label: 'Reported by',
    autocompleteType: AutocompleteTypes.USER,
    options: {
      renderCell: (value, { createdBy, reportedBy }) =>
        reportedBy
          ? routes.user.createLink(reportedBy, value)
          : routes.user.createLink(createdBy, value),
      filterType: 'autocomplete',
    },
  },
  {
    name: 'balanceInHuf.expected',
    label: 'Expected Balance (HUF)',
    type: 'currency',
  },
  {
    name: 'balanceInHuf.reported',
    label: 'Reported Balance (HUF)',
    type: 'currency',
  },
  {
    name: 'balanceInHuf.difference',
    label: 'Balance Difference (HUF)',
    type: 'currency',
  },
  {
    name: 'balanceInEur.expected',
    label: 'Expected Balance (EUR)',
    type: 'currency_eur',
  },
  {
    name: 'balanceInEur.reported',
    label: 'Reported Balance (EUR)',
    type: 'currency_eur',
  },
  {
    name: 'balanceInEur.difference',
    label: 'Balance Difference (EUR)',
    type: 'currency_eur',
  },
  {
    name: 'handledBy.fullName',
    label: 'Handled by',
    autocompleteType: AutocompleteTypes.USER,
    options: {
      renderCell: (value, { handledBy }) =>
        handledBy ? routes.user.createLink(handledBy, value) : null,
      filterType: 'autocomplete',
    },
  },
  {
    name: 'handledAt',
    label: 'Handled at',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filterOptions: Object.values(AuditLogStatus),
      filterType: 'dropdown',
    },
  },
  {
    name: 'auditEntityType',
    label: 'Audited entity type',
    options: {
      renderCell: (value, { auditEntityType }) => {
        return auditEntityType ? value : AuditEntityType.USER;
      },
      filterOptions: Object.values(AuditEntityType),
      filterType: 'dropdown',
    },
  },
];

function AuditLogsTable({ auditLogs, inline = false }) {
  const [sortModel, setSortModel] = useState({
    name: 'auditLogSeqId',
    sort: 'desc',
  });

  return (
    <CollectionTable>
      <Table
        title="Audit Log List"
        collectionName="auditLogs"
        query={auditLogs ? undefined : GET_AUDIT_LOGS}
        data={auditLogs}
        columns={columns}
        inline={inline}
        sortModel={sortModel}
        onSortModelChange={model => setSortModel(model)}
      />
    </CollectionTable>
  );
}

AuditLogsTable.propTypes = {
  auditLogs: Types.auditLogs,
  inline: PropTypes.bool,
};

export default AuditLogsTable;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../../../utils/formatters';
import useGetCheckingByCustomer from '../../../checking/useGetCheckingByCustomer';
import { Link } from '../../../lib/components';
import OverrideFreeElbCardChecking from '../../../events/components/EventChecking/OverrideFreeElbCardChecking/OverrideFreeElbCardChecking';

const QrCodeResultDialog = ({
  elbCardData,
  open,
  setOpen,
  setData,
  isError,
  toggleQrReading,
  qrCodeContent,
  v2TicketData,
  contentIsNotELBCardNumber,
  setContentIsNotELBCardNumber,
  eventId,
  methodWay,
  elbCardError,
}) => {
  console.log({ elbCardData, v2TicketData });
  const customerId =
    v2TicketData?.ticket !== null && !isError && !elbCardData
      ? v2TicketData?.ticket?.customer?.id
      : elbCardData?.customer[0]?._id;
  const customerName =
    v2TicketData?.ticket !== null && !isError && !elbCardData
      ? v2TicketData?.ticket?.customer?.fullName
      : elbCardData?.customer[0].fullName;
  const payload = {
    customerId,
    checkingMethod: methodWay,
    eventId,
  };
  const { data: checkingStatData, remove } = useGetCheckingByCustomer(payload);
  const data = elbCardData || {};
  const rows =
    isError || !elbCardData
      ? []
      : [
          // {
          //   label: 'Card Number',
          //   value:
          //     data.cardNumber !== 'ELB' || !data.cardNumber ? data.cardNumber : data.onlineCardNumber,
          // },
          {
            label: 'Status',
            value: data.status,
          },
          {
            label: 'Picture',
            value: data.customer[0]?.profilePicture?.url ? (
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box
                  style={{ display: 'flex', justifyContent: 'center', margin: 8, width: '100%' }}
                >
                  <img src={data.customer[0]?.profilePicture?.url} alt="" />
                </Box>
              </Box>
            ) : (
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                No picture
              </Typography>
            ),
          },
          {
            label: 'Customer',
            value: data.customer[0].fullName,
          },
          {
            label: 'Type',
            value:
              data.type[0].name === 'UNUSED_Product_related_type'
                ? data.elbCardProductSnapshot[0].name
                : data.type[0].name,
          },
          {
            label: 'Expiration Date',
            value: data.expirationDate ? formatDate(data.expirationDate) : null,
          },
        ];
  let v2TicketDataRows = [];
  if (v2TicketData?.ticket !== null && !isError && !elbCardData) {
    v2TicketDataRows.push(
      {
        label: 'Picture',
        value: v2TicketData?.ticket?.customer?.profilePicture?.url ? (
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box style={{ display: 'flex', justifyContent: 'center', margin: 8, width: '100%' }}>
              <img src={v2TicketData?.ticket?.customer?.profilePicture?.url} alt="" />
            </Box>
          </Box>
        ) : (
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            No picture
          </Typography>
        ),
      },
      {
        label: 'Customer',
        value: v2TicketData?.ticket?.customer?.fullName,
      },
    );
  }
  if (v2TicketData?.ticket !== null && !isError) {
    v2TicketDataRows.push(
      {
        label: 'Event',
        value: v2TicketData?.ticket?.event.name,
      },
      {
        label: 'Product',
        value: v2TicketData?.ticket?.product.name,
      },
      {
        label: 'Ticket Number',
        value: v2TicketData?.ticket?.ticketNumber,
      },
      {
        label: 'Status',
        value: v2TicketData?.ticket?.status,
      },
    );
  }

  const eventName = new URLSearchParams(window.location.search).get('eventName');

  return (
    <Dialog open={open} style={{ marginTop: '16px' }}>
      <DialogContent>
        {isError || (v2TicketDataRows.length === 0 && rows.length === 0) ? (
          <Typography variant="h6" style={{ color: 'red', fontWeight: 'normal' }}>
            Could not find active ELB Card for received input: <strong>{qrCodeContent}</strong>
          </Typography>
        ) : (
          <Box>
            {checkingStatData && (
              <Box
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Box>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    style={{
                      fontWeight: 'normal',
                      color:
                        checkingStatData?.numberOfCheckedElbCards > 1
                          ? 'red'
                          : checkingStatData?.numberOfCheckedElbCards === 1
                          ? 'green'
                          : 'yellow',
                    }}
                  >
                    <>
                      Checked ELB Cards
                      {eventId ? (
                        `: ${checkingStatData?.numberOfCheckedElbCards}`
                      ) : (
                        <>
                          {' (in last 12 hours):'} <br />
                          {checkingStatData?.numberOfCheckedElbCards}
                        </>
                      )}
                    </>
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    style={{
                      fontWeight: 'normal',
                      color:
                        checkingStatData?.numberOfCheckedTickets > 1
                          ? 'red'
                          : checkingStatData?.numberOfCheckedTickets === 1
                          ? 'green'
                          : 'yellow',
                    }}
                  >
                    <>
                      Checked Tickets
                      {eventId ? (
                        `: ${checkingStatData?.numberOfCheckedTickets}`
                      ) : (
                        <>
                          {' (in last 12 hours):'} <br />
                          {checkingStatData?.numberOfCheckedTickets}
                        </>
                      )}
                    </>
                  </Typography>
                </Box>
                {(checkingStatData?.numberOfCheckedTickets > 1 ||
                  checkingStatData?.numberOfCheckedElbCards > 1) && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={
                      eventId
                        ? `/event-checking-stats?eventId=${eventId}&eventName=${encodeURIComponent(
                            eventName,
                          )}&inputValue=${customerName}`
                        : '/checking-history'
                    }
                  >
                    History
                  </Button>
                )}
              </Box>
            )}

            <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: 'normal' }}>
              {`Input: ${qrCodeContent}`}
            </Typography>
            {elbCardData && (
              <Typography variant="h4" component="h2" gutterBottom>
                {`ELB Card: ${
                  data.cardNumber === 'ELB' ? data.onlineCardNumber : data.cardNumber
                } `}
              </Typography>
            )}

            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {rows.map(({ value }, index) => (
                <Grid container key={index}>
                  <Grid item xs={12}>
                    <Typography variant="h4" component={'div'} align="center">
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {contentIsNotELBCardNumber && (
                <Box style={{ marginTop: '6px' }}>
                  <Divider style={{ marginBottom: '6px', width: '100%', color: 'black' }} />
                  <Typography variant={!elbCardData ? 'h4' : 'h5'} component="div" align="center">
                    Ticket Data
                  </Typography>
                  {v2TicketDataRows.map(({ value }, index) => (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          variant={!elbCardData ? 'h4' : 'body1'}
                          component="div"
                          align="center"
                        >
                          {value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block' }}>
        {elbCardError?.response?.data?.issue !== 'FREE_WITH_ELB_CARD_TIME_INTERVAL_ERROR' ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
              //setData(null);
              toggleQrReading();
              setContentIsNotELBCardNumber(false);
              remove();
            }}
          >
            OK
          </Button>
        ) : (
          <OverrideFreeElbCardChecking
            closeOnClick={() => {
              setOpen(false);
              //setData(null);
              toggleQrReading();
              setContentIsNotELBCardNumber(false);
              remove();
            }}
            elbCardNumber={qrCodeContent}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeResultDialog;

import React from 'react';

import AddTicketProductToOrder from '../AddTicketProductToOrder';
import { Button } from '@material-ui/core';
import { Toolbar } from '../../../../common/components';
import AddElbCardProductToOrder from '../AddElbCardProductToOrder';
import ClearProductOrder from '../ClearProductOrder';
import { Link } from '../../../../lib/components';

function ProductOrderToolbar({ order, defaultBillingCustomerForTickets }) {
  const { isEmpty } = order;
  return (
    <Toolbar>
      <ClearProductOrder order={order} />
      <AddTicketProductToOrder
        defaultBillingCustomerForTickets={defaultBillingCustomerForTickets}
      />
      <AddElbCardProductToOrder />
      <Button
        component={Link}
        to={'/order-product-summary'}
        disabled={isEmpty}
        variant="contained"
        color="primary"
      >
        Next
      </Button>
    </Toolbar>
  );
}

export default ProductOrderToolbar;

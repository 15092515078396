import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { format, formatDistanceToNowStrict } from 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import routes from '../../../../../utils/routes';
import useGetCardReadingNumbers from '../../../hooks/useGetCardReadingNumbers';
import { Link } from '../../../../lib/components';

const sharedTableCellStyle = { padding: '8px' };

function EventCheckingStatistics({ isTabDisplay = false }) {
  //Layout settings
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [cardReadings, setCardReadings] = useState([]);

  // Read input parameters
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get('eventId');
  const eventName = urlParams.get('eventName');

  // Fetch event checking statistics
  const fetchEventCheckingStatistics = async (eventId) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      }
    }
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}checking/events/${eventId}`, options);
    console.log('fetchEventCheckingStatistics', response.data);
    setCardReadings(response.data);
  }

  useEffect(() => {
    fetchEventCheckingStatistics(eventId);
  }, [eventId]);

  const { data, isSuccess, isLoading, isError } = useGetCardReadingNumbers(eventId);
  const { numberOfCardReadings, numberOfUniqueCardReadings } = data || {};

  //Get table filter from params
  const inputValue = urlParams.get('inputValue');
  const [tableFilter, setTableFilter] = useState(inputValue);

  const filteredCardReadings = useMemo(() => {
    if (!tableFilter) {
      return cardReadings;
    } else {
      return cardReadings.filter(reading => {
        const nameContainsFilter = reading.customerFullName.includes(tableFilter);
        const emailContainsFilter = reading.customerEmail.includes(tableFilter);
        const cardNumberContainsFilter = reading?.checkedElbCard?.cardNumber.includes(tableFilter);
        const onlineCardNumberContainsFilter = reading?.checkedElbCard?.onlineCardNumber.includes(
          tableFilter,
        );
        const ticketNumberContainsFilter = reading?.checkedTicket?.ticketNumber.includes(
          tableFilter,
        );
        return (
          nameContainsFilter ||
          emailContainsFilter ||
          cardNumberContainsFilter ||
          onlineCardNumberContainsFilter ||
          ticketNumberContainsFilter
        );
      });
    }
  }, [cardReadings, tableFilter]);

  return (
    <Box>
      {!isTabDisplay && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px' }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/event-checking?eventId=${eventId}&eventName=${encodeURIComponent(eventName)}`}
          >
            Event Checking
          </Button>
        </Box>
      )}

      <Typography variant="h4" style={{ margin: 4 }}>
        Summary
      </Typography>
      <List dense={true}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#3f51b5' }}>
              <CreditCardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Number of checkings"
            secondary={
              isLoading
                ? 'Loading...'
                : isSuccess
                ? `${numberOfCardReadings} checkings`
                : isError
                ? 'ERROR'
                : '-'
            }
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#3f51b5' }}>
              <PeopleOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Number of unique customers"
            secondary={
              isLoading
                ? 'Loading...'
                : isSuccess
                ? `${numberOfUniqueCardReadings} unique customers`
                : isError
                ? 'ERROR'
                : '-'
            }
          />
        </ListItem>
      </List>
      <Typography variant="h4" style={{ margin: 4 }}>
        List of card readings
      </Typography>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant="h4" style={{ marginRight: '4px' }}>
          Filter:
        </Typography>
        <TextField
          variant="outlined"
          value={tableFilter}
          onChange={e => setTableFilter(e.target.value)}
          label="Table Filter"
          placeholder="Table Filter"
          style={{ width: '60%', marginBottom: '8px' }}
        />
      </Box>
      <TableContainer component={Paper}>
        {/*  DESKTOP - TABLE VIEW */}
        <Box style={{ display: isMobile ? 'none' : 'block' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={sharedTableCellStyle}>Profile picture</TableCell>
                <TableCell style={sharedTableCellStyle}>Name</TableCell>
                <TableCell style={sharedTableCellStyle}>Email</TableCell>
                <TableCell style={sharedTableCellStyle}>Checking number</TableCell>
                <TableCell style={sharedTableCellStyle}>Reading timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCardReadings.length === 0 ? (
                <TableRow>
                  <TableCell style={sharedTableCellStyle}>No checkings.</TableCell>
                </TableRow>
              ) : (
                filteredCardReadings.map(cardReading => {
                  const {
                    _id: id,
                    createdDate,
                    customerEmail,
                    customerFullName,
                    customerId,
                    checkedElbCard,
                    checkedTicket,
                    profilePicture,
                  } = cardReading;
                  const { cardNumber, onlineCardNumber } = checkedElbCard || {};
                  const { ticketNumber } = checkedTicket || {};
                  const humanReadableDateBetweenNowAndCheckDate = formatDistanceToNowStrict(
                    new Date(createdDate),
                    { addSuffix: true, includeSeconds: true },
                  );
                  return (
                    <TableRow key={id}>
                      <TableCell style={sharedTableCellStyle} align="left">
                        {profilePicture?.url ? (
                          <img src={profilePicture.url} alt={customerFullName} height={50} />
                        ) : (
                          'No picture'
                        )}
                      </TableCell>
                      <TableCell style={sharedTableCellStyle}>
                        {routes.customer.createLink(customerId, customerFullName)}
                      </TableCell>
                      <TableCell style={sharedTableCellStyle}>
                        {routes.customer.createLink(customerId, customerEmail)}
                      </TableCell>
                      <TableCell style={sharedTableCellStyle}>
                        {onlineCardNumber || cardNumber || ticketNumber}
                      </TableCell>
                      <TableCell style={sharedTableCellStyle}>
                        {`${format(
                          new Date(createdDate),
                          'yyyy-MM-dd HH:mm:ss',
                        )} (${humanReadableDateBetweenNowAndCheckDate})`}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Box>

        {/* MOBILE - CARD VIEW */}
        <Box style={{ display: isMobile ? 'block' : 'none' }}>
          {filteredCardReadings.length === 0 ? (
            <Box>
              <Typography>No checkings.</Typography>
            </Box>
          ) : (
            filteredCardReadings.map(cardReading => {
              const {
                _id: id,
                createdDate,
                customerEmail,
                customerFullName,
                customerId,
                checkedElbCard,
                checkedTicket,
                profilePicture,
              } = cardReading;
              const { cardNumber, onlineCardNumber } = checkedElbCard || {};
              const { ticketNumber } = checkedTicket || {};
              const humanReadableDateBetweenNowAndCheckDate = formatDistanceToNowStrict(
                new Date(createdDate),
                { addSuffix: true, includeSeconds: true },
              );
              return (
                <Box
                  key={id}
                  style={{
                    border: '1px solid #ccc',
                    marginBottom: '8px',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <Typography>
                    <strong>Checking Number:</strong>{' '}
                    {onlineCardNumber || cardNumber || ticketNumber}
                  </Typography>
                  <Typography>
                    <strong>Customer Name:</strong>{' '}
                    {routes.customer.createLink(customerId, customerFullName)}
                  </Typography>
                  <Typography>
                    <strong>Customer Email:</strong>{' '}
                    {routes.customer.createLink(customerId, customerEmail)}
                  </Typography>
                  <Box>
                    <strong>Profile Picture:</strong> <br />
                    {profilePicture?.url ? (
                      <img
                        src={profilePicture?.url}
                        alt="Profile"
                        width="50px"
                        style={{ display: 'block' }}
                      />
                    ) : (
                      'No picture'
                    )}
                  </Box>
                  <Typography>
                    <strong>Reading timestamp:</strong>{' '}
                    {`${format(
                      new Date(createdDate),
                      'yyyy-MM-dd HH:mm:ss',
                    )} (${humanReadableDateBetweenNowAndCheckDate})`}
                  </Typography>
                </Box>
              );
            })
          )}
        </Box>
      </TableContainer>
    </Box>
  );
}

export default EventCheckingStatistics;

import React from 'react';
import { Card, CardContent, Grid, Table, TableCell, TableRow } from '@material-ui/core';

import { InfoCardRow } from '../../../../common/components';
import OrderProductChangeCalculator from '../OrderProductChangeCalculator';
import ProductOrderTimer from '../ProductOrderTimer';
import BillingPreviewProductOrder from '../BillingPreviewProductOrder';

function OrderProductItemsInfoCard({
  order,
  paidAmounts,
  setPaidAmounts,
  terminal,
  setTerminal,
  paymentMode,
  setPaymentMode,
  currencyOption,
  setCurrencyOption,
  exchangeRate,
}) {
  const { products, expiresAt } = order;
  const tickets = products.filter(product => product.type === 'ticket');
  const elbCards = products.filter(product => product.type === 'elb-card');

  const rows = [
    {
      label: 'Number of Ticket products',
      value: tickets.length,
    },
    {
      label: 'Number of ELB Card products',
      value: elbCards.length,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Table>
          <TableRow>
            <TableCell>
              <ProductOrderTimer expiresAt={expiresAt} />

              <OrderProductChangeCalculator
                order={order}
                paidAmounts={paidAmounts}
                setPaidAmounts={setPaidAmounts}
                terminal={terminal}
                setTerminal={setTerminal}
                paymentMode={paymentMode}
                setPaymentMode={setPaymentMode}
                currencyOption={currencyOption}
                setCurrencyOption={setCurrencyOption}
                exchangeRate={exchangeRate}
              />

              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
              >
                {rows.map(({ label, value }) => (
                  <Grid item key={label}>
                    <InfoCardRow label={label} value={value} />
                  </Grid>
                ))}
              </Grid>
            </TableCell>
            <TableCell style={{ display: 'flex' }}>
              <BillingPreviewProductOrder order={order} />
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  );
}

export default OrderProductItemsInfoCard;

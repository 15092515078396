import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ActiveElbCard from './dashboard-statistics/ActiveElbCard';
import EventsInNextWeek from './dashboard-statistics/EventsInNextWeek';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import PurchasedElbCards from './dashboard-statistics/PurchasedElbCards';
import PurchasedTickets from './dashboard-statistics/PurchasedTickets';
import PurchasedTicketCardChart from './dashboard-statistics/PurchasedTicketCardChart';
import TabPanel from '../TabPanel';
import CurrentEventsTab from './dashboard-statistics/CurrentEventsTab';
import { useGetCurrentUser } from '../../../auth/hooks';

const useStyles = makeStyles(theme => ({
  dashboard: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(1),
  },
}));

const TABS = {
  TAB_DASHBOARD_DEMO: 'TAB_DASHBOARD_DEMO',
  TAB_DASHBOARD_CURRENT_EVENTS: 'TAB_DASHBOARD_CURRENT_EVENTS',
};

function Dashboard() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(TABS.TAB_DASHBOARD_CURRENT_EVENTS);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };
  const {
    data: { currentUser },
  } = useGetCurrentUser();
  const accessExternalUser = currentUser?.role?.name === 'External ELB Card Reader';

  //return <div className={classes.dashboard} />;
  return (
    <Box>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Current Event" value={TABS.TAB_DASHBOARD_CURRENT_EVENTS} />
          {!accessExternalUser && <Tab label="Demo Dashboard" value={TABS.TAB_DASHBOARD_DEMO} />}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TABS.TAB_DASHBOARD_CURRENT_EVENTS}>
        <CurrentEventsTab userIsExternal={accessExternalUser} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_DASHBOARD_DEMO}>
        <Grid container alignItems="center" spacing={2} style={{ padding: '8px' }}>
          <ActiveElbCard />
          <EventsInNextWeek />
        </Grid>
        <PurchasedElbCards />
        <PurchasedTickets />
        <PurchasedTicketCardChart />
      </TabPanel>
    </Box>
  );
}

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import useGetElbCardBySearchKeyword from '../../../checking/useGetElbCardBySearchKeyword.hook';
import { QrCodeResultDialog } from '../QrCodeResultDialog';
import { useGetV2TicketByTicketNumber } from '../../../tickets-v2/useGetV2TicketByTicketNumber';
import { useCreateNewCardReading } from '../../../events/components/EventChecking/hooks/useCreateNewCardReading';
import { MethodWayEnum } from '../../../elb-card-reading/components/NewElbCardReading/method-enum';
import { useSnackbar } from '../../../lib/hooks';
import { useCreateTicketChecking } from '../../../events/components/EventChecking/hooks/useCreateTicketChecking';

const CAMERA_TYPES = Object.freeze({
  FRONT_FACING: 'user',
  BACK_CAMERA: 'environment',
});

const validateReadContentFormat = data => {
  const isOnlineELBCardNumber = /^ELBo\d{5}x[A-Za-z0-9]{6}$/.test(data);
  const isELBCardNumber = /^(ELB)?\d{5}[A-Z]$/.test(data);
  //TODO const isTicketNumber hiányzik
  const isTicketNumber2ndGen = /^\d{4}o\dt\dx\d[a-zA-Z]{10}$/.test(data);
  const isV2TicketNumber = /^[a-zA-Z0-9]{10}$/.test(data);
  return isOnlineELBCardNumber || isELBCardNumber || isTicketNumber2ndGen || isV2TicketNumber;
};

const readContentIsNotELBCardNumber = data => {
  return !/^ELBo\d{5}x[A-Za-z0-9]{6}$/.test(data) && !/^(ELB)?\d{5}[A-Z]$/.test(data);
};

const QrCodeReading = ({ isEventChecking = false }) => {
  const [data, setData] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    data: newFoundElbCardData,
    isSuccess,
    refetch,
    isError: isApiResponseError,
  } = useGetElbCardBySearchKeyword(data);

  const [contentIsNotELBCardNumber, setContentIsNotELBCardNumber] = useState(false);

  const {
    data: v2TicketData,
    refetch: fetchV2TicketByTicketNumber,
    isSuccess: isTicketSuccess,
  } = useGetV2TicketByTicketNumber(data);

  //Save Card reading to database
  const {
    isError: isCreateCardReadingError,
    isSuccess: isCreateCardReadingSuccess,
    mutate: createNewElbCardReading,
    error: elbCardError,
  } = useCreateNewCardReading(data);

  const {
    mutate: createNewTicketReading,
    isSuccess: isCreateTicketReadingSuccess,
    isError: isCreateTicketReadingError,
    error: ticketError,
  } = useCreateTicketChecking();

  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get('eventId');
  const methodWay = isEventChecking
    ? MethodWayEnum.EVENT_CHECKING_METHOD
    : MethodWayEnum.LAST_12_HOURS_METHOD;
  const values = {
    cardNumber: newFoundElbCardData?.[0]?.onlineCardNumber || newFoundElbCardData?.[0]?.cardNumber,
    userToken: window.localStorage.getItem('token'),
    methodWay: methodWay,
    eventId: eventId,
  };
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isCreateCardReadingSuccess || isCreateTicketReadingSuccess) {
      enqueueSnackbar('Succesfully created reading for read data!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isCreateCardReadingSuccess, isCreateTicketReadingSuccess]);

  useEffect(() => {
    if (isCreateCardReadingError || isCreateTicketReadingError) {
      const errorMsg =
        (elbCardError &&
          elbCardError?.response?.data?.issue !== 'FREE_WITH_ELB_CARD_TIME_INTERVAL_ERROR') ||
        ticketError
          ? `Couldn't create reading for read data: ${ticketError?.response?.data?.error ||
              elbCardError?.response?.data?.error}`
          : elbCardError?.response?.data?.msg;
      enqueueSnackbar(errorMsg, 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isCreateCardReadingError, isCreateTicketReadingError]);

  useEffect(() => {
    if (isSuccess || isApiResponseError) {
      setIsLoading(false);
      setOpen(true);
    }
  }, [isApiResponseError, isSuccess]);

  useEffect(() => {
    if (contentIsNotELBCardNumber && v2TicketData?.ticket !== null && isTicketSuccess) {
      createNewTicketReading({
        ticketNumber: v2TicketData?.ticket?.ticketNumber,
        eventId: eventId,
        userToken: window.localStorage.getItem('token'),
        methodWay: methodWay,
      });
    }
  }, [contentIsNotELBCardNumber, isTicketSuccess, v2TicketData]);

  useEffect(() => {
    if (isSuccess && newFoundElbCardData.length > 0 && !contentIsNotELBCardNumber) {
      createNewElbCardReading(values);
    }
  }, [isSuccess, newFoundElbCardData]);

  const toggleQrReading = () => {
    setIsReading(previous => !previous);
    if (data) {
      setData(null);
    }
    if (isError) setIsError(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          columnGap: '15px',
        }}
      >
        <Button variant="contained" color="primary" onClick={toggleQrReading}>
          {isReading ? 'STOP CAMERA' : 'START CAMERA'}
        </Button>
        <Button variant="outlined" color="primary" onClick={() => setData(null)}>
          RESET READ DATA
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div>
          <Typography variant="h5" align="center">
            QR code content
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              marginTop: '8px',
            }}
          >
            {data || 'No QR code content yet'}
          </Typography>
        </div>
      </div>
      {isLoading && (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'16px'}>
          <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Checking ELB Card...
          </Typography>
          <CircularProgress />
        </Box>
      )}
      {isSuccess && (
        <QrCodeResultDialog
          elbCardData={newFoundElbCardData?.find(card => card.status === 'Active')}
          open={open}
          setOpen={setOpen}
          setData={setData}
          isError={
            isApiResponseError ||
            ((newFoundElbCardData.length === 0 ||
              newFoundElbCardData?.find(card => card.status === 'Active') === undefined) &&
              v2TicketData.ticket === null)
          }
          toggleQrReading={toggleQrReading}
          qrCodeContent={data}
          v2TicketData={v2TicketData}
          contentIsNotELBCardNumber={contentIsNotELBCardNumber}
          setContentIsNotELBCardNumber={setContentIsNotELBCardNumber}
          eventId={eventId}
          methodWay={methodWay}
          elbCardError={elbCardError}
        />
      )}
      {isError && !data && (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'16px'}>
          <Typography variant="h4" style={{ fontWeight: 'bold', color: 'red' }}>
            Read QR Code content format is not valid.
          </Typography>
        </Box>
      )}
      {isReading && (
        <QrReader
          delay={500}
          onResult={(result, error) => {
            if (!!result) {
              console.log('onResult result', result);
              const validatedText = validateReadContentFormat(result?.text);
              const isV2TicketNumber = readContentIsNotELBCardNumber(result?.text)
              console.log({ contentIsV2TicketNumber: contentIsNotELBCardNumber, bool: isV2TicketNumber && validatedText });
              if (isV2TicketNumber && validatedText) {
                setData(result?.text);
                setIsLoading(true);
                toggleQrReading();
                refetch(result?.text);
                fetchV2TicketByTicketNumber(result?.text);
                setContentIsNotELBCardNumber(readContentIsNotELBCardNumber(result?.text));
              } else if (validatedText) {
                setData(result?.text);
                setIsLoading(true);
                toggleQrReading();
                refetch(result?.text);
              } else {
                setIsError(true);
              }
            }

            if (!!error) {
              if (error?.message) {
                console.log('QrReader error', error);
                console.log('QrReader error?.message', error?.message);
              }
            }
          }}
          style={{
            width: '100%',
          }}
          constraints={{
            facingMode: CAMERA_TYPES.BACK_CAMERA,
          }}
        />
      )}
    </div>
  );
};

export default QrCodeReading;

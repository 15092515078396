import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useDownloadBilling = () => {
    return useMutation({
        mutationKey: ['download-billing'],
        mutationFn: async (payload) => {
            const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL
            const { billingId, billingType, pdfType } = payload;

            const defaultRoute = billingType === 'Invoice' ? 'invoices/get-invoice-pdf' : billingType === 'Receipt' ? 'receipts/get-receipt-pdf' : billingType === 'Certificate' ? 'certificates/get-certificate-pdf' : ''
            const pdfTypeQueryParam = billingType === 'Invoice' || billingType === 'Receipt' ? `?pdfType=${pdfType}` : ''
            const route = `${defaultRoute}/${billingId}${pdfTypeQueryParam}`
            const response = await axios.get(
                `${REACT_APP_API_V2_BASE_URL}/${route}`, {
                responseType: 'blob', // Important for handling binary file downloads
            });

            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false
    });
};

import React, { useEffect, useState } from 'react';
import { useGetEventChart } from '../hooks/useGetEventChart';
import { Box, TextField, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

function EventCheckingChart() {
  const [bucketTimeIntervalInMinutes, setBucketTimeIntervalInMinutes] = useState(5);
  const eventId = new URLSearchParams(window.location.search).get('eventId');
  const payload = {
    eventId,
    bucketTimeIntervalInMinutes,
  };
  const { data, isSuccess } = useGetEventChart(payload);
  const [xAxis, setXAxis] = useState([]);
  const [checkedElbCard, setCheckedElbCard] = useState([]);
  const [checkedTicket, setCheckedTicket] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const xAxisData = data.map(d => d.date);
      setXAxis(xAxisData);

      const checkedElbCardNumber = data.map(d => d.checkedElbCard);
      setCheckedElbCard(checkedElbCardNumber);

      const checkedTicketNumber = data.map(d => d.checkedTicket);
      setCheckedTicket(checkedTicketNumber);
    }
  }, [isSuccess, data, setXAxis, setCheckedElbCard, setCheckedTicket]);

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography style={{ marginBottom: '8px', fontWeight: 'bold' }}>
          The provided time interval is in minutes:
        </Typography>
        <TextField
          variant="outlined"
          type="number"
          id="bucketTimeIntervalInMinutes"
          name="bucketTimeIntervalInMinutes"
          value={bucketTimeIntervalInMinutes}
          onChange={e => {
            setBucketTimeIntervalInMinutes(e.target.value);
          }}
          label="Time interval"
          style={{ width: '60%', alignSelf: 'center' }}
        />
      </Box>
      {isSuccess && (
        <Box style={{ width: '700px' }}>
          <Bar
            data={{
              labels: xAxis,
              datasets: [
                {
                  label: 'Checked ELB Card',
                  data: checkedElbCard,
                  backgroundColor: '#00B4D8',
                  borderColor: '#00B4D8',
                  borderWidth: 0.5,
                },

                {
                  label: 'Checked Ticket',
                  data: checkedTicket,
                  backgroundColor: '#3f51b5',
                  borderColor: '#3f51b5',
                  borderWidth: 0.5,
                },
              ],
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default EventCheckingChart;

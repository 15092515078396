import React, { useEffect } from 'react';
import { useDownloadBilling } from './useDownloadBilling';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSnackbar } from '../lib/hooks';

function DownloadBilling({ billingId, billingType, pdfType, billingNumber }) {
  const payload = {
    billingId,
    billingType,
    pdfType,
  };
  const { data, isSuccess, mutate, isError, isLoading } = useDownloadBilling(payload);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = () => {
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${billingNumber}-${pdfType}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Couldn't edit billing account!", 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  return (
    <IconButton onClick={handleDownload} disabled={isLoading}>
      <GetAppIcon />
    </IconButton>
  );
}

export default DownloadBilling;

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useGetEventStats } from '../hooks/useGetEventStats';
import EventIcon from '@material-ui/icons/Event';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EventCheckingChart from './EventCheckingChart';

function EventCheckingStats() {
  const eventId = new URLSearchParams(window.location.search).get('eventId');
  const { data, isLoading, isError, isSuccess } = useGetEventStats(eventId);
  const { soldTickets, checkedElbCard, cardCheckedTicket, v2TicketCheckedTicket, tableData } =
    data || {};
  return (
    <Box>
      <Box>
        <Typography variant="h4" style={{ margin: 4 }}>
          Event Summary
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: '#3f51b5' }}>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Sold tickets for event"
              secondary={
                isLoading
                  ? 'Loading...'
                  : isSuccess
                  ? `${soldTickets} tickets`
                  : isError
                  ? 'ERROR'
                  : '-'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: '#3f51b5' }}>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Checked ELB Cards for event"
              secondary={
                isLoading
                  ? 'Loading...'
                  : isSuccess
                  ? `${checkedElbCard} checked ELB cards`
                  : isError
                  ? 'ERROR'
                  : '-'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: '#3f51b5' }}>
                <ConfirmationNumberIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Checked Tickets for event (from checking)"
              secondary={
                isLoading
                  ? 'Loading...'
                  : isSuccess
                  ? `${cardCheckedTicket} checked tickets`
                  : isError
                  ? 'ERROR'
                  : '-'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: '#3f51b5' }}>
                <ConfirmationNumberIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Checked Tickets for event (from ticket)"
              secondary={
                isLoading
                  ? 'Loading...'
                  : isSuccess
                  ? `${v2TicketCheckedTicket} checked in tickets`
                  : isError
                  ? 'ERROR'
                  : '-'
              }
            />
          </ListItem>
        </List>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>Ticket Type</TableCell>
              <TableCell>Sold Tickets</TableCell>
              <TableCell>
                Checked Tickets
                <br /> (from checking)
              </TableCell>
              <TableCell>
                Checked Tickets
                <br /> (from tickets)
              </TableCell>
            </TableHead>
            <TableBody>
              {tableData?.map(data => {
                const {
                  ticketType,
                  soldTicket,
                  checkedTicket_fromChecking,
                  checkedTicket_fromTicket,
                } = data;
                return (
                  <TableRow>
                    <TableCell>{ticketType}</TableCell>
                    <TableCell>{soldTicket}</TableCell>
                    <TableCell>{checkedTicket_fromChecking}</TableCell>
                    <TableCell>{checkedTicket_fromTicket}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Typography variant="h4" style={{ margin: 4 }}>
          Chart
        </Typography>
        <EventCheckingChart />
      </Box>
    </Box>
  );
}

export default EventCheckingStats;

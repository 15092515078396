import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useReverseBilling } from './useReverseBilling';
import { useSnackbar } from '../lib/hooks';

function ReverseBilling({ billingId, billingType, billingNumber, isReversed }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const payload = {
    billingId,
    billingType,
  };
  const { data, isSuccess, isError, isLoading, mutate } = useReverseBilling();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${billingNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      enqueueSnackbar('Reversed billing document successfully!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Couldn't reverse billing document!", 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpen} disabled={isReversed}>
        Reverse
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Reverse {billingNumber}</DialogTitle>
        <DialogContent>Are you sure you want to reverse the document?</DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              mutate(payload);
            }}
            disabled={isLoading}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ReverseBilling;

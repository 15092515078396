import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useGetElbCardPurchases = (values) => {
    const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
    return useQuery({
        queryKey: ['elb-card-purchases', values],
        queryFn: async () => {
            const { from, to, currency } = values
            console.log({ values });
            const response = await axios.get(
                `${REACT_APP_API_V2_BASE_URL}/statistics/elb-card-purchases?from=${from}&to=${to}&currency=${currency}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { TabPanel } from '../../../../common/components';
import EventCheckingStatistics from './EventCheckingStatistics';
import EventCheckingStats from './EventCheckingStats';

const TABS = {
  TAB_EVENT_CHECKING_HISTORY: 'TAB_EVENT_CHECKING_HISTORY',
  TAB_EVENT_CHECKING_STATISTICS: 'TAB_EVENT_CHECKING_STATISTICS',
};

function EventCheckingStatPage() {
  const [tabValue, setTabValue] = useState(TABS.TAB_EVENT_CHECKING_HISTORY);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="History, Summary" value={TABS.TAB_EVENT_CHECKING_HISTORY} />
          <Tab label="Statistics" value={TABS.TAB_EVENT_CHECKING_STATISTICS} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_HISTORY}>
        <EventCheckingStatistics />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_STATISTICS}>
        <EventCheckingStats />
      </TabPanel>
    </Box>
  );
}

export default EventCheckingStatPage;

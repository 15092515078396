import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';

function AuditLogInfoCard({
  auditLog: {
    prettyId,
    createdBy,
    handledBy,
    createdAt,
    handledAt,
    status,
    balanceInHuf,
    balanceInEur,
    auditLogSeqId,
  },
}) {
  const { expected: expectedInHuf, reported: reportedInHuf, difference: differenceInHuf } =
    balanceInHuf || {};
  const { expected: expctedInEur, reported: reportedInEur, difference: differenceInEur } =
    balanceInEur || {};
  const rows = [
    {
      label: 'Sequential audit log ID',
      value: `AL${auditLogSeqId}`,
    },
    {
      label: 'Employee',
      value: routes.user.createLink(createdBy),
    },
    {
      label: 'Date',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Expected Balance (HUF)',
      value: formatForint(expectedInHuf),
    },
    {
      label: 'Reported Balance (HUF)',
      value: formatForint(reportedInHuf),
    },
    {
      label: 'Balance Difference (HUF)',
      value: formatForint(differenceInHuf),
    },
    {
      label: 'Expected Balance (EUR)',
      value: formatEuro(expctedInEur),
    },
    {
      label: 'Reported Balance (EUR)',
      value: formatEuro(reportedInEur),
    },
    {
      label: 'Balance Difference (EUR)',
      value: formatEuro(differenceInEur),
    },
    {
      label: 'Handled by',
      value: handledBy ? routes.user.createLink(handledBy) : '',
    },
    {
      label: 'Handled at',
      value: handledAt ? formatDateTime(handledAt) : '',
    },
    {
      label: 'Status',
      value: status,
    },
  ];
  return <InfoCard title={prettyId} rows={rows} />;
}

AuditLogInfoCard.propTypes = {
  auditLog: Types.auditLog.isRequired,
};

export default AuditLogInfoCard;

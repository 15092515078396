import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useGetElbCardPurchases } from './useGetElbCardPurchases';
import { format, subDays } from 'date-fns';
import { FillerSpinner } from '../lib/components';
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Bar } from 'react-chartjs-2';
import ElbCardPurchasesTable from './ElbCardPurchasesTable';

const useStyles = makeStyles({
  toggleButton: {
    '&.MuiToggleButton-sizeSmall': {
      padding: '2px',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      color: 'white',
      backgroundColor: '#3f51b5',
    },
  },
});

function ElbCardPurchases() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [xAxis, setXAxis] = useState([]);
  const [officePurchases, setOfficePurchases] = useState([]);
  const [onlinePurchases, setOnlinePurchases] = useState([]);

  const classes = useStyles();
  const [currency, setCurrency] = useState('HUF');

  const handleChange = (_, newCurrency) => {
    if (newCurrency !== null) {
      setCurrency(newCurrency);
    }
  };

  const values = {
    from: fromDate,
    to: toDate,
    currency,
  };

  const { data, isLoading, isSuccess, isError, error } = useGetElbCardPurchases(values);
  const { elbCardPurchases, summedAmount } = data || {};

  useEffect(() => {
    if (isSuccess) {
      const xAxisData = elbCardPurchases.map(d => d.date);
      setXAxis(xAxisData);

      const officePurchasesData = elbCardPurchases.map(d => d.orders);
      setOfficePurchases(officePurchasesData);

      const onlinePurchasesData = elbCardPurchases.map(d => d.payments);
      setOnlinePurchases(onlinePurchasesData);
    }
  }, [isSuccess, data, setXAxis, setOfficePurchases, setOnlinePurchases]);

  const today = new Date();
  const formattedToday = format(today, 'yyyy-MM-dd');
  const thirtyDaysSubFromToday = format(subDays(today, 30), 'yyyy-MM-dd');

  useEffect(() => {
    setFromDate(thirtyDaysSubFromToday);
    setToDate(formattedToday);
  }, [thirtyDaysSubFromToday, formattedToday]);

  const handleFromDateChange = e => {
    const date = e.target.value;
    setFromDate(date);
  };

  const handleToDateChange = e => {
    const date = e.target.value;
    setToDate(date);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const datasetIndex = tooltipItem.datasetIndex;
          let value = data.datasets[datasetIndex].data[tooltipItem.index];
          const dataLabel = data.datasets[datasetIndex]?.label;
          const numberOfPurchases =
            dataLabel === 'Office'
              ? officePurchases[tooltipItem.index]?.numberOfItems
              : onlinePurchases[tooltipItem.index]?.numberOfItems;

          let formattedValue = value;
          if (parseInt(value) >= 1000) {
            formattedValue = value
              .toString()
              .split(/(?=(?:...)*$)/)
              .join('.');
          }
          return `${dataLabel}: ${formattedValue} ${
            currency === 'HUF' ? 'Ft' : '€'
          } (qty: ${numberOfPurchases})`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            userCallback: function(value) {
              return value
                .toString()
                .split(/(?=(?:...)*$)/)
                .join('.');
            },
          },
        },
      ],
    },
    legend: { labels: { fontSize: 15 } },
  };

  const toggleButtonStyle = { paddig: '0px', '&.MuiToggleButton-sizeSmall': { padding: '3px' } };

  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', height: '95%', overflow: 'hidden' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography style={{ margin: '0px 4px', fontWeight: 'bold' }}>From:</Typography>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            value={fromDate}
            onChange={handleFromDateChange}
            style={{ margin: '0px 3px', maxWidth: '200px', backgroundColor: 'transparent' }}
          />
          <Typography style={{ margin: '0px 4px', fontWeight: 'bold' }}>To:</Typography>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            value={toDate}
            onChange={handleToDateChange}
            style={{ margin: '0px 3px', maxWidth: '200px', backgroundColor: 'transparent' }}
          />
          <ToggleButtonGroup
            value={currency}
            exclusive
            onChange={handleChange}
            size="small"
            style={{ margin: '8px' }}
          >
            <ToggleButton value="HUF" className={classes.toggleButton}>
              HUF
            </ToggleButton>
            <ToggleButton value="EUR" className={classes.toggleButton}>
              EUR
            </ToggleButton>
          </ToggleButtonGroup>
          <button
            style={{ padding: '2px', margin: '2px' }}
            onClick={() => {
              setFromDate(thirtyDaysSubFromToday);
              setToDate(formattedToday);
            }}
          >
            Clear
          </button>
        </Box>
        {isLoading && (
          <Box style={{ padding: '8px', margin: '8px' }}>
            <FillerSpinner />
          </Box>
        )}
        {isError && (
          <Box
            style={{
              display: 'flex',
              width: '60%',
              alignSelf: 'center',
              justifyContent: 'center',
              margin: '8px',
            }}
          >
            <Alert severity="error" style={{ padding: '6px', margin: '4px' }}>
              <AlertTitle>Something went wrong</AlertTitle>
              <Typography>An error occured durint data loading: </Typography>
              <Typography>{error?.message}</Typography>
            </Alert>
          </Box>
        )}
        <Box style={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}>
          {isSuccess &&
            (elbCardPurchases.length > 0 ? (
              <Bar
                data={{
                  labels: xAxis,
                  responsive: true,
                  datasets: [
                    {
                      label: 'Office',
                      data: officePurchases.map(office => office?.amount),
                      backgroundColor: '#00B4D8',
                      borderColor: '#00B4D8',
                      borderWidth: 0.5,
                    },
                    {
                      label: 'Online',
                      data: onlinePurchases.map(online => online?.amount),
                      backgroundColor: '#3f51b5',
                      borderColor: '#3f51b5',
                      borderWidth: 0.5,
                    },
                  ],
                }}
                options={options}
              />
            ) : (
              <Box
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  margin: '8px',
                }}
              >
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  <Typography>
                    There is no purchases (office or online) in the given date interval
                  </Typography>
                </Alert>
              </Box>
            ))}
        </Box>
      </Box>
      {isSuccess && (
        <Grid style={{ display: 'flex', flexDirection: 'column', marginTop: '28px' }}>
          <hr style={{ width: '60%', alignSelf: 'center' }} />
          <Typography
            style={{ textAlign: 'center', fontSize: '18px', padding: '8px', margin: '18px' }}
          >
            Summed amount of purchased ELB Cards{' '}
            {summedAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            {currency === 'HUF' ? 'Ft' : '€'}
          </Typography>
          <Typography
            style={{ textAlign: 'center', fontSize: '16px', padding: '8px', margin: '8px' }}
          >
            If you want to choose "EUR" for currency, make sure the "from" date is after there is
            any available exchange rate. To check the first exchange rate go to{' '}
            <Link href={'/exchange-rate'} target="_blank">
              Exchange rate page
            </Link>
          </Typography>
        </Grid>
      )}
      <ElbCardPurchasesTable from={fromDate} to={toDate} />
    </>
  );
}

export default ElbCardPurchases;

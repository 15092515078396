import React from 'react';

import routes from '../../../../utils/routes';

import { GET_ACCOUNTS } from '../../graphql';

import { Table, CollectionTable } from '../../../common/components';

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filterType: 'textField',
      renderCell: (value, { id }) => routes.account.createLink(id, value),
    },
  },
  {
    name: 'sales.todayInHuf',
    label: 'Sales of Today (HUF)',
    type: 'currency',
  },
  {
    name: 'sales.todayInEur',
    label: 'Sales of Today (EUR)',
    type: 'currency_eur',
  },
  {
    name: 'balanceInHuf',
    label: 'Balance (HUF)',
    type: 'currency',
  },
  {
    name: 'balanceInEur',
    label: 'Balance (EUR)',
    type: 'currency_eur',
  },
  {
    name: 'active',
    label: 'Status',
    options: {
      renderCell: active => (active ? 'Active' : 'Inactive'),
      // filterList: ['Active'],
      display: false,
      filter: false,
    },
  },
];

function AccountsTable() {
  return (
    <CollectionTable>
      <Table
        title="Accounts List"
        collectionName="accounts"
        query={GET_ACCOUNTS}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default AccountsTable;

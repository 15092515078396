import { Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useGetCurrentUser } from '../../../../auth/hooks';
import { useOverrideFreeElbCardChecking } from '../hooks/useOverrideFreeElbCardChecking';
import { useSnackbar } from '../../../../lib/hooks';
import { MethodWayEnum } from '../../../../elb-card-reading/components/NewElbCardReading/method-enum';

function OverrideFreeElbCardChecking({ closeOnClick, elbCardNumber }) {
  const user = useGetCurrentUser().data?.currentUser;
  const access = user?.role?.name === 'Admin';
  console.log({ user, access });

  const { mutate, isSuccess, isError } = useOverrideFreeElbCardChecking();
  const { enqueueSnackbar } = useSnackbar();
  const values = {
    cardNumber: elbCardNumber,
    userToken: window.localStorage.getItem('token'),
    methodWay: MethodWayEnum.EVENT_CHECKING_METHOD,
    eventId: new URLSearchParams(window.location.search).get('eventId'),
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        'Succesfully overrode issue with free ELB Card interval and created reading for ELB Card!',
        'success',
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      );
      closeOnClick();
    }
  }, [isSuccess, enqueueSnackbar]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Could not overrode issue with free ELB Card interval', 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);
  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={closeOnClick} variant="outlined" color="primary">
        Close
      </Button>
      <>
        {access && (
          <Button variant="contained" color="primary" onClick={() => mutate(values)}>
            OVERRIDE
          </Button>
        )}
      </>
    </Box>
  );
}

export default OverrideFreeElbCardChecking;

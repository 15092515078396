import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link } from '../../../../lib/components';

const useGetCurrentEventsForDashboard = dateIntervall => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['current-events-dashboard', dateIntervall],
    queryFn: async () => {
      const userToken = localStorage.getItem('token');
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}dashboard/current-events?dateIntervall=${dateIntervall}`,
        { headers: { Authorization: `${userToken}` } },
      );
      const data = response.data;
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: !!dateIntervall,
  });
};

function CurrentEventsTab({ userIsExternal }) {
  const [dateIntervall, setDateIntervall] = useState(3);
  const { data, isSuccess, isError, isLoading, error } = useGetCurrentEventsForDashboard(
    dateIntervall,
  );
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TextField
        name="dateIntervall"
        id="dateIntervall"
        value={dateIntervall}
        onChange={e => setDateIntervall(e.target.value)}
        variant="outlined"
        label="Date Intervall"
        type="number"
        disabled={userIsExternal}
      />
      {isError && (
        <Box style={{ margin: '6px' }}>
          <Alert severity="error">
            <AlertTitle>Error!</AlertTitle>
            <Typography>Something went wrong!</Typography>
            {error?.response?.data && <Typography>{error?.response?.data}</Typography>}
          </Alert>
        </Box>
      )}
      {isLoading && (
        <Box style={{ margin: '6px' }}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Box>
      )}
      {isSuccess &&
        (data.length > 0 ? (
          <Box>
            {data.map((event, index) => {
              const {
                id,
                name,
                location,
                from,
                until,
                isHappening,
                soldV1Tickets,
                soldV2Tickets,
                checkedTickets,
              } = event;

              return (
                <Card
                  key={index}
                  style={{
                    margin: '6px',
                    backgroundColor: isHappening ? 'palegreen' : 'white',
                  }}
                >
                  <CardContent>
                    <Box>
                      <Typography>
                        Name: <b>{name}</b>{' '}
                      </Typography>
                      <Typography>
                        Location: <b>{location}</b>{' '}
                      </Typography>
                      <Typography>
                        Start Date: <b>{format(new Date(from), 'yyyy-MM-dd hh:mm')}</b>
                      </Typography>
                      <Typography>
                        End Date: <b>{format(new Date(until), 'yyyy-MM-dd hh:mm')}</b>
                      </Typography>
                      <Typography>Statistics: </Typography>
                      <Box style={{ paddingLeft: '12px' }}>
                        <Typography>
                          Sold v1 Tickets: <b>{soldV1Tickets}</b>
                        </Typography>
                        <Typography>
                          Sold v2 Tickets: <b>{soldV2Tickets}</b>
                        </Typography>
                        <Typography>
                          Checked Tickets: <b>{checkedTickets}</b>
                        </Typography>
                      </Box>
                      {isHappening && (
                        <Button
                          component={Link}
                          to={`/event-checking?eventId=${id}&eventName=${name}`}
                          variant="contained"
                          color="primary"
                          style={{ margin: '6px' }}
                        >
                          Go To Checking
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        ) : (
          <Box style={{ margin: '6px' }}>
            <Alert severity="warning">
              <AlertTitle>No upcoming events</AlertTitle>
              <Typography>
                No event is happening now, happened already or will be happening in the next{' '}
                {dateIntervall} days
              </Typography>
            </Alert>
          </Box>
        ))}
    </Box>
  );
}

export default CurrentEventsTab;

import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import EventChecking from '../EventChecking/EventChecking';
import { TabPanel } from '../../../../common/components';
import EventCheckingTickets from '../EventCheckingTickets';
import CheckingSearchBar from '../../../../checking/CheckingSearchBar.component';
import { QrCodeReading } from '../../../../qr-code-reading/components/QrCodeReading';
import EventCheckingStatistics from '../EventChecking/EventCheckingStatistics';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useGetEvent } from '../../../hooks';
import { format, isAfter, isBefore } from 'date-fns';
import EventCheckingChart from '../EventChecking/EventCheckingChart';

const TABS = {
  TAB_SEARCH_BAR_CHECK: 'TAB_SEARCH_BAR_CHECK',
  TAB_EVENT_CHECKING_ZEBRA: 'TAB_EVENT_CHECKING_ZEBRA',
  TAB_EVENT_CHECKING_TICKET_LIST: 'TAB_EVENT_CHECKING_TICKET_LIST',
  TAB_QRCODE_READER: 'TAB_QRCODE_READER',
  TAB_HISTORY_STATS: 'TAB_HISTORY_STATS',
  TAB_CHECKING_CHART: 'TAB_CHECKING_CHART',
};

function EventCheckingPage() {
  const [tabValue, setTabValue] = useState(TABS.TAB_SEARCH_BAR_CHECK);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };
  const eventId = new URLSearchParams(window.location.search).get('eventId');
  const { data: event, isSuccess } = useGetEvent(eventId);
  console.log({ event });
  const alertTypeLogic =
    event?.isFreeWithElbCard &&
    isAfter(new Date(), new Date(event?.freeWithElbCardFrom)) &&
    isBefore(new Date(), new Date(event?.freeWithElbCardUntil));
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box marginTop={'8px'}>
        <Alert severity={alertTypeLogic ? 'success' : 'error'}>
          <AlertTitle>Event is {alertTypeLogic ? '' : 'NOT '}free with ELB Card</AlertTitle>
          {event?.freeWithElbCardFrom && event?.freeWithElbCardUntil && (
            <Typography>
              Event is free with ELB Card between:{' '}
              {format(new Date(event?.freeWithElbCardFrom), 'yyyy-MM-dd HH:mm')} and{' '}
              {format(new Date(event?.freeWithElbCardUntil), 'yyyy-MM-dd HH:mm')}
            </Typography>
          )}
        </Alert>
      </Box>
      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Search Bar Check" value={TABS.TAB_SEARCH_BAR_CHECK} />
          <Tab label="Zebra" value={TABS.TAB_EVENT_CHECKING_ZEBRA} />
          <Tab label="Tickets List" value={TABS.TAB_EVENT_CHECKING_TICKET_LIST} />
          <Tab label="Camera QR Code Reader" value={TABS.TAB_QRCODE_READER} />
          <Tab label="Checking history, stats" value={TABS.TAB_HISTORY_STATS} />
          <Tab label="Checking chart" value={TABS.TAB_CHECKING_CHART} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TABS.TAB_SEARCH_BAR_CHECK}>
        <Typography variant="h3">Search bar check</Typography>
        <CheckingSearchBar isEventChecking={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_ZEBRA}>
        <Typography variant="h3">Zebra</Typography>
        {/* <EventChecking /> */}
        <CheckingSearchBar isEventChecking={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_TICKET_LIST}>
        {/* <Typography variant="h3" style={{ textAlign: 'center', marginBottom: '8px' }}>
          Tickets List
        </Typography> */}
        <EventCheckingTickets />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_QRCODE_READER} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <Typography variant="h3">QR Code Reader</Typography>
        </Box>
        <QrCodeReading isEventChecking={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_HISTORY_STATS} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <Typography variant="h3">Checking History, Stats</Typography>
        </Box>
        <EventCheckingStatistics isTabDisplay={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_CHECKING_CHART} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <Typography variant="h3">Checking Chart</Typography>
        </Box>
        <EventCheckingChart />
      </TabPanel>
    </Box>
  );
}

export default EventCheckingPage;

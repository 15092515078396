import * as yup from 'yup';
import { isBefore, isAfter } from 'date-fns';
import { isNil } from 'lodash';

export const MAX_INTEGER = 2147483647;

export const id = msg => yup.string(msg);

/**
 * @todo Create dates with invalid past/future validation
 */
export const date = yup.date('Please provide a valid date!');
export const optionalDate = date.nullable().default(null);

export const integer = yup
  .number()
  .integer()
  .max(MAX_INTEGER);

export const positiveInteger = integer.min(1);
export const zeroOrPositiveInteger = integer.min(0);

/**
 * User
 */
export const email = yup
  .string()
  .email(`Please enter a valid email address`)
  .max(100);
export const role = id();
export const password = yup.string();
export const confirmPassword = yup
  .string()
  .test('match', "Your passwords don't match.", function equals(cp) {
    return cp === this.parent.password;
  });
export const firstName = yup.string();
export const lastName = yup.string();

export const createUser = yup.object().shape({
  email: email.required(),
  role: role.required(),
});
export const updateUser = yup.object().shape({
  firstName: firstName.required(),
  lastName: lastName.required(),
  role: role.required(),
});
export const profile = yup.object().shape({
  firstName: firstName.required(),
  lastName: lastName.required(),
});

export const activation = yup.object().shape({
  firstName: firstName.required(),
  lastName: lastName.required(),
  password: password.required(),
  passwordAgain: confirmPassword.required(),
});

export const login = yup.object().shape({
  email: email
    .required()
    .test('emailUppercaseCheck', 'Your email cannot contain uppercase characters!', email => {
      return [...email].every(character => !character.match(/[A-Z]/));
    }), //.matches(/[a-z.@]/, ""),
  password: password.required(),
});

export const passwordReset = yup.object().shape({
  password: password.required(),
  passwordAgain: confirmPassword.required(),
});

export const passwordResetRequest = yup.object().shape({
  email: email.required(),
});

/**
 * Account
 */
export const accountName = yup
  .string()
  .min(3)
  .max(200);
export const account = yup.object().shape({
  name: accountName.required(`Account schema is required`),
});

/**
 * Customer
 */
export const customerName = yup
  .string()
  .min(2)
  .max(100);
export const createCustomer = yup.object().shape({
  email: email.required(`Email address is required`),
  firstName: customerName.required('First name is required'),
  lastName: customerName.required('Last name is required'),
});

/**
 * ELB card type
 */
export const elbCardTypeName = yup
  .string()
  .min(2)
  .max(100);
export const elbCardTypePrice = zeroOrPositiveInteger;
export const createElbCardType = yup.object().shape({
  name: elbCardTypeName.required('Name is required'),
  price: elbCardTypePrice.required(`Price is required`),
});
export const updateElbCardType = yup.object().shape({
  name: elbCardTypeName.required('Name is required'),
});

/**
 * Order
 */

/**
 * ELB card
 */
export const expirationDate = yup
  .date()
  .min(new Date(), 'Expiration date has to be in the future!');
export const elbCardNumber = yup.string();
export const updateELBCard = yup.object().shape({
  expirationDate: expirationDate.required(`Expiration date is required!`),
});
const isNumeric = numeric => !isNaN(numeric) && !isNaN(parseFloat(numeric));
export const validateElbCard = yup.object().shape({
  elbCardNumber: elbCardNumber.test('elbCardNumber', 'ELB card number is invalid!', input => {
    // ELB card number is no optional
    if (!input) return true;
    if (input.length === 0) {
      return true;
    }

    const isMinLength = input.length === 6;
    const isFirstFiveCharsOk = [...input.slice(0, 5)].every(number => isNumeric(number));
    const lastChar = input.slice(-1);
    const isLastCharacterUppercase =
      isNumeric(lastChar) === false && lastChar === lastChar.toUpperCase();
    const isGoodFormat = isMinLength && isFirstFiveCharsOk && isLastCharacterUppercase;
    return isGoodFormat;
  }),
  customer: yup
    .object()
    .nullable()
    .required(`Customer is required!`),
  elbCardType: yup
    .object()
    .nullable()
    .required(`ELB Card type is required!`),
  isWithoutPhysicalCardToggle: yup.bool().when(['elbCardNumber'], {
    is: elbCardNumber => {
      // ELB card number is no optional
      if (!elbCardNumber) return false;
      if (elbCardNumber.length === 0) {
        return false;
      }

      const isMinLength = elbCardNumber.length === 6;
      const isFirstFiveCharsOk = [...elbCardNumber.slice(0, 5)].every(number => isNumeric(number));
      const lastChar = elbCardNumber.slice(-1);
      const isLastCharacterUppercase =
        isNumeric(lastChar) === false && lastChar === lastChar.toUpperCase();
      const isGoodFormat = isMinLength && isFirstFiveCharsOk && isLastCharacterUppercase;
      return isGoodFormat;
    },
    then: yup.bool().oneOf([false], 'Toggle must be NOT checked in case of ELB card number input!'),
    otherwise: yup
      .bool()
      .required()
      .oneOf([true], 'Toggle must be checked!'),
  }),
  isAddPhysicalCardToOnlineCard: yup.bool().when(['elbCardType'], {
    is: elbCardType => {
      if (elbCardType) {
        if (elbCardType.name.toLowerCase().includes('extra physical')) {
          return true;
        } else return false;
      } else return false;
    },
    then: yup.bool().oneOf([true], 'Checkbox must BE checked in case of ELB card number input!'),
  }),
});

/** Ticket */
export const validateTicket = yup.object().shape({
  event: yup
    .object()
    .nullable()
    .required(),
  ticketType: yup.string().required(),
  customer: yup.object().nullable(),
  ticketNumber: yup.string(),
  withELBCard: yup.bool(),
});

/**
 * Transaction
 */
export const TRANSACTION_TYPES = {
  Income: 'Income',
  Expense: 'Expense',
  Transfer: 'Transfer',
};
export const type = yup.string().oneOf(Object.values(TRANSACTION_TYPES));
export const amount = positiveInteger;
export const amountInHuf = zeroOrPositiveInteger;
export const amountInEur = zeroOrPositiveInteger;
export const description = yup.string().max(500);
export const transaction = yup.object().shape({
  source: yup
    .object()
    // Validate accounts involved in a Transfer
    .when('type', (txType, schema) =>
      txType === TRANSACTION_TYPES.Transfer
        ? schema.test('match', 'Source and target accounts must not be the same.', function equals(
          source,
        ) {
          return Boolean(source && this.parent.target && source.id !== this.parent.target.id);
        })
        : schema,
    )
    .when('type', (txType, schema) =>
      txType === TRANSACTION_TYPES.Expense || txType === TRANSACTION_TYPES.Transfer
        ? schema.required('Please select a source account.')
        : schema,
    ),

  target: yup
    .object()
    // Validate accounts involved in a Transfer
    .when('type', (txType, schema) =>
      txType === TRANSACTION_TYPES.Transfer
        ? schema.test('match', 'Source and target accounts must not be the same.', function equals(
          target,
        ) {
          return Boolean(target && this.parent.source && target.id !== this.parent.source.id);
        })
        : schema,
    )
    .when('type', (txType, schema) =>
      txType === TRANSACTION_TYPES.Income || txType === TRANSACTION_TYPES.Transfer
        ? schema.required('Please select a target account.')
        : schema,
    ),
  type: type.required(),
  amountInHuf: amountInHuf
    .test('balance-check', 'Amount (HUF) exceeds available balance (HUF).', function (value) {
      const { source } = this.parent;
      if (!source) return true;

      const balance = source.balanceInHuf || null;

      return balance !== null && value <= balance;
    }),
  amountInEur: amountInEur
    .test('balance-check', 'Amount (EUR) exceeds available balance (EUR).', function (value) {
      const { source } = this.parent;
      if (!source) return true;

      const balance = source.balanceInEur || null;

      return balance !== null && value <= balance;
    }),
  description,
});

/**
 * Export
 */
export const spreadSheetTitle = yup
  .string()
  .min(3)
  .max(80);

/** Event */
const testDates = (from, until) => {
  return (from !== null && until !== null) ? isBefore(from, until) : true;
}
export const eventName = yup.string();
export const eventFrom = date;
export const eventUntil = date;
export const eventLocation = yup.string();
export const eventTicketLimit = positiveInteger;
export const event = yup.object().shape({
  name: eventName.required('Event name is required!'),
  from: eventFrom
    .required('Start date is required!')
    .test('before', 'Start date is after the end date!', function before(from) {
      return isBefore(from, this.parent.until);
    }),
  until: eventUntil
    .required('End date is required!')
    .test('after', 'End date is before the start date!', function after(until) {
      return isAfter(until, this.parent.from);
    }),
  location: eventLocation,
  ticketLimit: eventTicketLimit,
  billingAccount: yup.string(),
  form: yup.string(),
  isExternal: yup.bool().required(),
  startSales: yup.bool().required(),
  freeWithElbCardFrom: date.nullable().default(null)
    .test('before', 'Free with ELB Card start date date is after the end date!', function before(freeWithElbCardFrom) {
      return testDates(freeWithElbCardFrom, this.parent.freeWithElbCardUntil);
    }),
  freeWithElbCardUntil: date.nullable().default(null)
    .test('after', 'Free with ELB Card end date date is before the start date!', function after(freeWithElbCardUntil) {
      return testDates(this.parent.freeWithElbCardFrom, freeWithElbCardUntil);
    }),
  canCheckingFrom: date.nullable().default(null)
    .test('before', 'Can Check start date date is after the end date!', function before(canCheckingFrom) {
      return testDates(canCheckingFrom, this.parent.canCheckingUntil);
    }),
  canCheckingUntil: date.nullable().default(null)
    .test('after', 'Can Check end date date is before the start date!', function after(canCheckingUntil) {
      return testDates(this.parent.canCheckingFrom, canCheckingUntil);
    }),
  billingAccountId: yup.string().optional().nullable()
});

/** Checking */
export const cardNumber = yup.string();
export const eventId = yup.string();
export const checking = yup.object().shape({
  cardNumber: cardNumber.required('ELB Card number is required!'),
});

/** Ticket Type */
export const ticketTypeName = yup.string();
export const ticketTypeExpirationDate = optionalDate;
export const ticketTypeLimit = zeroOrPositiveInteger;
export const ticketTypeNormalPrice = zeroOrPositiveInteger;
export const ticketTypeElbCardPrice = zeroOrPositiveInteger;
export const ticketType = yup.object().shape({
  name: ticketTypeName.required('Name is required!'),
  expirationDate: ticketTypeExpirationDate,
  limit: ticketTypeLimit,
  normalPrice: ticketTypeNormalPrice.test(
    'atLeastOne',
    'Neither price or ELB Card price is set!',
    function atLeastOne(normalPrice) {
      return !(isNil(this.parent.elbCardPrice) && isNil(normalPrice));
    },
  ),
  elbCardPrice: ticketTypeElbCardPrice.test(
    'atLeastOne',
    'Neither price or ELB Card price is set!',
    function atLeastOne(elbCardPrice) {
      return !(isNil(this.parent.normalPrice) && isNil(elbCardPrice));
    },
  ),
  // startSales: yup.bool().optional(),
});

/** Report Balance */
export const reportBalance = yup.object().shape({
  amountInHuf: integer,
  amountInEur: integer,
});

/** Global Billing */
export const globalBilling = yup.object().shape({
  manualBilling: yup.bool(),
});

/** Global ELB Card */
export const globalELBCard = yup.object().shape({
  billingAccount: yup.string(),
  formId: yup.string().required(),
  defaultExpirationTime: yup
    .number()
    .min(1)
    .max(120)
    .required(),
});

/** Global OnlinePaymentsSettings */
export const globalOnlinePaymentsSettings = yup.object().shape({
  maxItemsInCart: yup.number(),
});

/** Billing Account */
export const billingAccount = yup.object().shape({
  name: yup
    .string()
    // .min(3)
    .max(200)
    .required(),
  publicKey: yup.string().required(),
  privateKey: yup.string().required(),
});

/** Terminal */
export const terminal = yup.object().shape({
  name: yup
    .string()
    .min(3)
    .max(200)
    .required(),
  account: yup.string(),
});

export const updateTerminal = yup.object().shape({
  account: yup.string().required(),
});

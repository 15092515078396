import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useGetEventChart = (payload) => {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['get-event-chart', payload],
        queryFn: async () => {
            const { eventId, bucketTimeIntervalInMinutes } = payload
            const response = await axios.get(`${REACT_APP_SERVER_URL}checking/events/chart/${eventId}?bucketTimeIntervalInMinutes=${bucketTimeIntervalInMinutes}`);
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { MethodWayEnum } from '../elb-card-reading/components/NewElbCardReading/method-enum';
import useGetCheckingByCustomer from './useGetCheckingByCustomer';
import { useSnackbar } from '../lib/hooks';
import { useCreateNewCardReading } from '../events/components/EventChecking/hooks/useCreateNewCardReading';
import { Link } from '../lib/components';
import OverrideFreeElbCardChecking from '../events/components/EventChecking/OverrideFreeElbCardChecking/OverrideFreeElbCardChecking';

function CheckingSearchBarCreateWithResultDialog({ elbCard, isEventChecking = false }) {
  const eventId = new URLSearchParams(window.location.search).get('eventId');
  const eventName = new URLSearchParams(window.location.search).get('eventName');
  const methodWay = isEventChecking
    ? MethodWayEnum.EVENT_CHECKING_METHOD
    : MethodWayEnum.LAST_12_HOURS_METHOD;
  const customerId = elbCard.customer[0]._id;
  const customerName = elbCard.customer[0].fullName;

  const {
    data: createdData,
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    mutate,
    error,
  } = useCreateNewCardReading(elbCard?.onlineCardNumber);
  const handleCreateChecking = currentElbCard => {
    const values = {
      cardNumber: currentElbCard?.onlineCardNumber || currentElbCard?.cardNumber,
      userToken: window.localStorage.getItem('token'),
      methodWay,
      eventId,
    };
    mutate(values);
  };

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const hasTriggered = useRef(false);

  useEffect(() => {
    if (isCreateSuccess && !hasTriggered.current) {
      enqueueSnackbar('Succesfully created reading for ELB Card!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      setOpen(true);
      hasTriggered.current = true;
      refetch();
    }
  }, [isCreateSuccess, enqueueSnackbar]);

  useEffect(() => {
    if (isCreateError && !hasTriggered.current) {
      const errorMsg =
        error?.response?.data?.issue !== 'FREE_WITH_ELB_CARD_TIME_INTERVAL_ERROR'
          ? "Couldn't create reading for ELB Card!"
          : error?.response?.data?.msg;
      enqueueSnackbar(errorMsg, 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      setOpen(true);
      hasTriggered.current = true;
    }
  }, [isCreateError, enqueueSnackbar]);

  const payload = {
    customerId,
    checkingMethod: methodWay,
    eventId,
  };
  const { data: checkingStatData, refetch } = useGetCheckingByCustomer(payload);
  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => {
          handleCreateChecking(elbCard);
        }}
        color={'primary'}
        fullWidth
        disabled={createdData}
      >
        {createdData ? 'Checked' : 'Use ELB card for checking'}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Read Card Stats</DialogTitle>
        <DialogContent>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                style={{
                  color:
                    checkingStatData?.numberOfCheckedElbCards > 1
                      ? 'red'
                      : checkingStatData?.numberOfCheckedElbCards === 1
                      ? 'green'
                      : 'yellowgreen',
                }}
              >
                {`Checked ELB Cards${isEventChecking ? '' : ' (in last 12 hours)'}: ${
                  checkingStatData?.numberOfCheckedElbCards
                }`}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{
                  color:
                    checkingStatData?.numberOfCheckedTickets > 1
                      ? 'red'
                      : checkingStatData?.numberOfCheckedTickets === 1
                      ? 'green'
                      : 'yellowgreen',
                }}
              >
                {`Checked Tickets${isEventChecking ? '' : ' (in last 12 hours)'}: ${
                  checkingStatData?.numberOfCheckedTickets
                }`}
              </Typography>
            </Box>
            {(checkingStatData?.numberOfCheckedTickets > 1 ||
              checkingStatData?.numberOfCheckedElbCards > 1) && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={
                  isEventChecking
                    ? `/event-checking-stats?eventId=${eventId}&eventName=${encodeURIComponent(
                        eventName,
                      )}&inputValue=${customerName}`
                    : '/checking-history'
                }
              >
                History
              </Button>
            )}
          </Box>
        </DialogContent>
        <DialogActions style={{ display: 'block' }}>
          {error?.response?.data?.issue !== 'FREE_WITH_ELB_CARD_TIME_INTERVAL_ERROR' || !isCreateError ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(false);
              }}
            >
              OK
            </Button>
          ) : (
            <OverrideFreeElbCardChecking
              closeOnClick={() => {
                setOpen(false);
              }}
              elbCardNumber={elbCard?.onlineCardNumber || elbCard?.cardNumber}
            />
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CheckingSearchBarCreateWithResultDialog;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export default function useGetBillingPreviewForProductOrder(orderId) {
    return useQuery({
        queryKey: ['billing-preview', orderId],
        queryFn: async () => {
            const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL
            const response = await axios.get(
                `${REACT_APP_API_V2_BASE_URL}/billing/order-billing-preview/${orderId}`
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
        retry: 1
    });
};

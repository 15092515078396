import React, { useState } from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_BILLING_ACCOUNTS } from '../../graphql';

import { Collection } from '../../../common/components';
import BillingAccountsToolbar from '../BillingAccountsToolbar';
import BillingAccountsTable from '../BillingAccountsTable';
import BillingAccountCard from '../../../billing-accounts/BillingAccountCard.component';
import { Box, Button } from '@material-ui/core';
import CreateOrEditBillingAccountModal from '../../../billing-accounts/CreateOrEditBillingAccountModal.component';

function BillingAccounts() {
  const [open, setOpen] = useState(false);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box style={{ alignSelf: 'flex-end', margin: '8px' }}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Create
        </Button>
        <CreateOrEditBillingAccountModal
          open={open}
          setOpen={setOpen}
          billingAccount={null}
          isEditing={false}
        />
      </Box>
      <BillingAccountCard />
    </Box>
  );
  // return (
  //   <Collection
  //     toolbar={<BillingAccountsToolbar refetchQueries={[getOperationName(GET_BILLING_ACCOUNTS)]} />}
  //     table={<BillingAccountsTable />}
  //   />
  // );
}

export default BillingAccounts;

import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import DownloadBilling from './DownloadBilling.component';
import ReverseBilling from './ReverseBilling.component';

function BillingCard({ billing }) {
  const valueStyle = { fontWeight: 'bold' };
  return (
    <Card>
      <CardContent>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing ID:</Typography>
          <Typography style={valueStyle}>{billing?.billingId || 'No ID'}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing Type:</Typography>
          <Typography style={valueStyle}>{billing?.type}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Created At:</Typography>
          <Typography style={valueStyle}>{format(new Date(billing.createdAt), 'yyyy-MM-dd HH:mm')}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing Account ID:</Typography>
          <Typography style={valueStyle}>{billing?.billingAccountId}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Order ID:</Typography>
          <Typography style={valueStyle}>{'TBD'}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Summary:</Typography>
          <Typography style={valueStyle}>{billing?.sum}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>VAT Type:</Typography>
          <Typography style={valueStyle}>{'ÁFA (TBD)'}</Typography>
        </Box>
        {billing.successfullyIssued && (
          <Box>
            <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <Typography>Download PDF:</Typography>
              <Typography>
                {
                  <DownloadBilling
                    billingId={billing?.id}
                    billingType={billing.type}
                    pdfType={'basic'}
                    billingNumber={billing?.billingId}
                  />
                }
              </Typography>
            </Box>
            {billing.type !== 'Certificate' ? (
              (billing.type === 'Invoice' || billing.type === 'Receipt') && !billing.isReversed ? (
                <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  <Typography>Reverse:</Typography>
                  <Typography>
                    {
                      <ReverseBilling
                        billingId={billing?.id}
                        billingType={billing.type}
                        billingNumber={billing?.billinId}
                        isReversed={billing.isReversed}
                      />
                    }
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Box style={{ display: 'flex', gap: '20px' }}>
                    <Typography>Reverse Billing Number:</Typography>
                    <Typography style={valueStyle}>{billing.reverseNumber}</Typography>
                  </Box>
                  <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <Typography>Download (Reversed) PDF:</Typography>
                    <Typography>
                      {
                        <DownloadBilling
                          billingId={billing?.id}
                          billingType={billing.type}
                          pdfType={'reversed'}
                          billingNumber={billing.reverseNumber}
                        />
                      }
                    </Typography>
                  </Box>
                </Box>
              )
            ) : (
              <></>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default BillingCard;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';


export const useReverseBilling = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['reverse-billing'],
        mutationFn: async (payload) => {
            const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL
            const { billingId, billingType } = payload;

            const defaultRoute = billingType === 'Invoice' ? 'invoices/reverse-invoice' : billingType === 'Receipt' ? 'receipts/reverse-receipt' : ''
            if (defaultRoute === '') {
                return null
            }
            const route = `${defaultRoute}/${billingId}`
            const response = await axios.get(
                `${REACT_APP_API_V2_BASE_URL}/${route}`, {
                responseType: 'blob',
            });

            const data = await response.data;
            return data;
        },
        onSuccess: () =>
            queryClient.invalidateQueries(['billings']),
    });
};

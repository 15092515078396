import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useGetBillingsByType } from './useGetBillingsByType';
import { useDebounce } from '../common/hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';
import BillingCard from './BillingCard.component';
import CloseIcon from '@material-ui/icons/Close';

function BillingDocumentsPage() {
  const [keyword, setKeyword] = useState('');
  const [billingType, setBillingType] = useState('');
  const debouncedKeyword = useDebounce(keyword, 500);

  const payload = {
    keyword: debouncedKeyword,
    billingType,
  };
  const { data, isError, isLoading, isSuccess } = useGetBillingsByType(payload);

  const handleBillingTypeChange = event => {
    const selectedBillingType = event.target.value;
    setBillingType(selectedBillingType);
  };
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        alignItems={'center'}
        alignContent={'center'}
      >
        <TextField
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: '8px', width: '25%', minWidth: '120px' }}
          label="Search Keyword"
          type="text"
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
        />
        <FormControl variant="outlined" style={{ width: '15%', minWidth: '120px' }}>
          <InputLabel>Billing type</InputLabel>
          <Select
            id="billingType"
            name="billingType"
            value={billingType}
            onChange={handleBillingTypeChange}
            startAdornment={
              billingType !== '' && (
                <InputAdornment style={{ marginRight: '4px' }} position="start">
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setBillingType('');
                    }}
                  />
                </InputAdornment>
              )
            }
          >
            <MenuItem value="" disabled></MenuItem>
            <MenuItem value="invoices">Invoice</MenuItem>
            <MenuItem value="receipts">Receipt</MenuItem>
            <MenuItem value="certificates">Certificate</MenuItem>
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: '8px' }}
          variant="outlined"
          color="primary"
          onClick={() => {
            setKeyword('');
            setBillingType('');
          }}
        >
          Clear
        </Button>
      </Box>
      <Box>
        {isError && (
          <Box>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <Typography>Something went wrong!</Typography>
            </Alert>
          </Box>
        )}
        {isLoading && (
          <Box
            style={{
              position: 'relative',
              height: '110px',
            }}
          >
            <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
              <CircularProgress color="primary" />
            </LimitedBackdrop>
          </Box>
        )}
        {isSuccess &&
          (data.length > 0 ? (
            <Box>
              {data.map((billing, index) => {
                return <BillingCard billing={billing} key={index} />;
              })}
            </Box>
          ) : (
            <Box>
              <Alert severity="warning">
                <AlertTitle>Not found</AlertTitle>
                <Typography>No billing document was found with provided keyword.</Typography>
              </Alert>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default BillingDocumentsPage;

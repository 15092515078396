import React, { useEffect, useRef, useState } from 'react';

import { FillerSpinner, Link } from '../../../lib/components';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useGetProducts } from '../../hooks';
import CreateProductDialog from '../CreateProductDialog';
import { useDebounce } from '../../../common/hooks';
import { SUPPORTED_PRODUCT_TYPES_ARRAY, getHumanReadableProductType } from './products.utils';
import ProductCard from '../ProductCard';

function Products() {
  const [searchText, setSearchText] = useState('');
  const [lastSearchTextValue, setLastSearchTextValue] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [type, setType] = useState('');

  const {
    isError,
    isLoading,
    isSuccess,
    data: productData,
    refetch,
    isRefetching,
  } = useGetProducts({
    searchText: debouncedSearchText,
    type,
  });

  const handleSubmit = e => {
    e.preventDefault();
    refetch();
    if (searchTextInputRef?.current) {
      searchTextInputRef.current.focus();
      searchTextInputRef.current.select();
    }
  };

  useEffect(() => {
    if (debouncedSearchText) {
      setLastSearchTextValue(debouncedSearchText);
      if (lastSearchTextValue !== debouncedSearchText) {
        refetch();
        if (searchTextInputRef?.current) {
          searchTextInputRef.current.focus();
          searchTextInputRef.current.select();
        }
      }
    }
  }, [debouncedSearchText, refetch, lastSearchTextValue]);

  const searchTextInputRef = useRef(null);

  const clearSearchForm = () => {
    setSearchText('');
    setType('');
  };

  useEffect(() => {
    if (type) {
      refetch();
    }
  }, [type, refetch]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <TextField
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: '4px', width: '350px' }}
          label="Product Search"
          type="text"
          inputRef={searchTextInputRef}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FormControl required variant="outlined" style={{ margin: '4px', width: '150px' }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
          >
            {SUPPORTED_PRODUCT_TYPES_ARRAY.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {getHumanReadableProductType(type)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={clearSearchForm}
          style={{ margin: '4px' }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ margin: '4px' }}
        >
          Search
        </Button>
        <Button
          component={Link}
          variant="contained"
          color="primary"
          style={{ margin: '4px' }}
          to={'/create-product'}
        >
          Create Product (new page)
        </Button>
        <CreateProductDialog />
      </Box>
      {(isLoading || isRefetching) && <FillerSpinner />}
      {isError && <strong>Failed loading products</strong>}
      <ProductCard productData={productData} isSuccess={isSuccess} />
    </>
  );
}

export default Products;

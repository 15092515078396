import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useGetAccountOptions } from '../../../common/hooks';

import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';
import { formatEuro, formatForint } from '../../../../utils/formatters';
import { AccountType } from '../AccountFormFields/AccountFormFields';

function FormikCardAccountAutocomplete({ name, InputProps = {}, ...rest }) {
  const { loading, data } = useGetAccountOptions();
  const options = useMemo(
    () =>
      data
        ? [
            ...data.accounts.entries.filter(
              ({ type, active }) => active && type === AccountType.Card,
            ),
          ]
        : [],
    [data],
  );
  const getOptionLabel = useCallback(
    ({ name: accountName, balanceInHuf, balanceInEur }) =>
      `${accountName} (${formatForint(balanceInHuf)}) (${formatEuro(balanceInEur)})`,
    [],
  );

  return (
    <FAutocomplete
      isRequired
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      loading={loading}
      InputProps={{
        label: 'Account (with Card type)',
        ...InputProps,
      }}
      helperText={'You can select accounts with Card type.'}
      {...rest}
    />
  );
}

FormikCardAccountAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
};

export default FormikCardAccountAutocomplete;

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useGetBillingPreviewForProductOrder } from '../../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import useGetBillingAccounts from '../../../../billing-accounts/useGetBillingAccounts.hook';

function BillingPreviewProductOrder({ order }) {
  const { data, isError, error, isSuccess, refetch } = useGetBillingPreviewForProductOrder(
    order.id,
  );
  const { data: billingAccountsData } = useGetBillingAccounts();
  console.log({ data, error, billingAccountsData, order });
  useEffect(() => {
    if (order?.products?.length === 0) {
      refetch();
      console.log('refetched');
    }
  }, [order, refetch]);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h3" component="h1" style={{ margin: '10px 0px' }}>
        Billing Preview:
      </Typography>
      {isError && <Typography style={{ color: 'red' }}>{error?.response?.data}</Typography>}
      {isSuccess && (
        <Box>
          {data?.length > 0 ? (
            <Box>
              {data?.map((ba, index) => {
                const { billingAccountId, items } = ba;
                const currentBillingAccount = billingAccountsData.find(
                  baData => baData._id.toString() === billingAccountId.toString(),
                );
                const { billingType, name, vatType } = currentBillingAccount || {};
                return (
                  <Box marginBottom={'8px'} key={index}>
                    {billingAccountId === 'No Billing Account' ? (
                      <Typography>
                        <strong>{billingAccountId}</strong>
                      </Typography>
                    ) : (
                      <>
                        <Typography>
                          <strong>Name:</strong> {name}
                        </Typography>
                        <Typography>
                          <strong>Billing Type:</strong> {billingType}
                        </Typography>
                        <Typography>
                          <strong>VAT Type:</strong> {vatType}
                        </Typography>
                      </>
                    )}

                    <Box>
                      <Typography>
                        <strong>Products:</strong>
                      </Typography>
                      <Box>
                        <Table size="small" style={{ maxWidth: '300px' }}>
                          <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Customer</TableCell>
                          </TableHead>
                          <TableBody>
                            {items.map((item, i) => {
                              const { name, priceInHuf, priceInEur, type, event, customer } = item;
                              return (
                                <TableRow key={i}>
                                  <TableCell>{name}</TableCell>
                                  <TableCell>{`${priceInHuf}Ft/€${priceInEur}`}</TableCell>
                                  <TableCell>{type}</TableCell>
                                  <TableCell>{event?.name}</TableCell>
                                  <TableCell>
                                    {customer?.firstName} {customer?.lastName}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box>
              <Alert severity="warning">
                <AlertTitle>No Billing Account</AlertTitle>
                <Typography>
                  There is no billing account in current order. No billing document will be created.
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default BillingPreviewProductOrder;

import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';

function ReportBalanceFields() {
  return (
    <>
      <FTextField
        name="amountInHuf"
        type="number"
        fullWidth
        variant="outlined"
        label="Amount (HUF)"
        margin="normal"
      />

      <FTextField
        name="amountInEur"
        type="number"
        fullWidth
        variant="outlined"
        label="Amount (EUR)"
        margin="normal"
      />

      <FFormError />
    </>
  );
}

export default ReportBalanceFields;

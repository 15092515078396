import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useGetElbCardPurchaseTableData } from './useGetElbCardPurchaseTableData';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';

function ElbCardPurchasesTable({ from, to }) {
  const values = {
    from,
    to,
  };
  const { data, isLoading, isError, isSuccess } = useGetElbCardPurchaseTableData(values);
  const { elbCardPurchases } = data || {};
  return (
    <TableContainer component={Paper} style={{ display: 'flex', justifyContent: 'center' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Purchased Quantity</TableCell>
            <TableCell>Income</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && (
            <TableRow>
              <TableCell colSpan={4}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Typography>Something went wrong!</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow
              style={{
                position: 'relative',
                height: '110px',
              }}
            >
              <TableCell colSpan={4}>
                <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            (elbCardPurchases.length > 0 ? (
                elbCardPurchases.map(purchase => {
                const { productName, income, quantity, paymentChannel } = purchase;

                return (
                  <TableRow key={productName}>
                    <TableCell>{productName}</TableCell>
                    <TableCell>{paymentChannel}</TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{`${income} Ft`}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Alert severity="warning">
                    <AlertTitle>ELB Card Purchases</AlertTitle>
                    <Typography style={{ fontSize: '16px' }}>
                      There is no ELB Card purchase that can be displayed!
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ElbCardPurchasesTable;
